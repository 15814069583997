import React from 'react';
import { IntlProvider } from 'react-intl';
import Layout from '../components/Layout';
import Error from '../views/404';
import { DEFAULT_LOCALE } from '../i18n';
import enMessages from '../data/404/locales/en.json';
import flattenMessages from '../helpers/flattenMessages';

const ErrorPage = () => (
  <IntlProvider
    locale={DEFAULT_LOCALE}
    defaultLocale={DEFAULT_LOCALE}
    messages={flattenMessages(enMessages, '404')}
  >
    <Layout location="/404">
      <Error locale={DEFAULT_LOCALE} />
    </Layout>
  </IntlProvider>
);

export default ErrorPage;
