import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import Nav from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import {
  H1,
  Paragraph,
  ContentBlock,
} from '@bitcoin-portal/bitcoincom-storybook';
import { Wrapper } from './styled';
import SEO from './SEO';

const ErrorPage = ({ intl: { messages } }) => (
  <React.Fragment>
    <SEO />
    <Wrapper>
      <Nav />
      <ContentBlock
        image={messages['404.content.image']}
        imageAlt={messages['404.content.image-alt']}
      >
        <H1>
          <FormattedMessage id="404.content.title" />
        </H1>
        <Paragraph>
          <FormattedMessage id="404.content.subtitle" />
        </Paragraph>
        <Paragraph>
          <FormattedHTMLMessage id="404.content.action" />
        </Paragraph>
      </ContentBlock>
      <Footer />
    </Wrapper>
  </React.Fragment>
);

ErrorPage.propTypes = {
  intl: PropTypes.shape({
    messages: PropTypes.object,
  }).isRequired,
};
export default injectIntl(ErrorPage);
